import {Flex, Box, Button, Text} from 'rebass/styled-components';
import styled, {css} from 'styled-components';

export const Wrapper = styled(Flex)`
  position: relative;
  align-items: center;
  padding: 10px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 12px 32px -6px rgb(21 34 66 / 10%), 0 0 1px rgb(21 34 66 / 22%);
  min-height: 400px;
  
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`

export const VerticalDivider = styled(Flex)`
  width: 1px;
  background: #000;
  height: 300px;
  margin: 0 50px;
  @media (max-width: 1200px) {
    display: none;
  }
`

export const Results = styled(Flex)`
  height: 100%;
  padding: 30px 0;
  flex-direction: column;
  justify-content: flex-start;
  align-self: flex-start;
  @media (max-width: 1200px) {
    align-self: flex-start;
    margin-left: 25px;
  }
`

export const ResultItem = styled(Flex)`
  padding: 10px;
`

export const ImportInfo = styled(Flex)`
  flex-direction: column;
`

export const InfoHeader = styled(Flex).attrs(() => ({
  fontFamily: 'primary',
  color: 'text.secondary',
  fontSize: 3
}))`
  width: 100%;
  border-bottom: 1px solid #dedede;
  padding: 0 0 15px 0;
`

export const InfoItem = styled(Flex).attrs(() => ({
  fontFamily: 'primary',
  color: 'text.secondary',
  fontSize: 1
}))`
  align-items: center;
`

export const Form = styled.form`
  padding: 25px;  
  flex-direction: column;
  min-height: 300px;
  height: fit-content;
  min-width: 480px;
  align-self: flex-start;
  @media (max-width: 1200px) {
    width: 100%;
    min-width: 100%;
    min-height: fit-content;
  }
  @media (max-width: 600px) {
    padding: 10px;
  }
`

export const FormGroup = styled(Flex)`
  align-items: center;
  flex-wrap: wrap;
  position: relative;
`

export const FormLabel = styled(Text).attrs(({sx}) => ({
  fontFamily: 'primary'
}))`
  font-size: 16px;
  min-width: 45px;
  width: 102px;
  @media (max-width: 600px) {
    font-size: 14px;
    width: 75px;
  }
  @media (max-width: 420px) {
    font-size: 12px;
    width: 60px;
  }
`

export const FormSubmitButton = styled(Button)`
  padding: 10px;
  color: #000;
  border-radius: 12px;
  max-width: 160px;
  margin: 16px 0 0;

  @media (max-width: 470px) {
    margin-left: 0 !important;
    width: 100%;
    max-width: 100%;
  }
`

export const InputError = styled(Box).attrs(({sx}) => ({
  fontFamily: 'primary'
}))`
  width: 100%;
  color: red;
  font-size: 10px;
  font-weight: bold;
`

export const FormInput = styled(Box)`
  padding: 15px;
  font-size: 14px;
  min-width: 80px;
  height: 38px;
  border: 1px solid #000;
  border-radius: 6px;
  margin: 10px 0 10px 15px;
  max-width: 95px;
  
  @media (max-width: 420px) {
    min-width: 60px;
    width: 75px !important;
  }
  
  ${({ type }) => type === 'number' && css` 
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
    -moz-appearance: textfield; 
  `}
`

export const InputSmallText = styled(Text).attrs(() => ({
  fontFamily: 'primary'
}))`
  color: #d3d3d3;
  font-size: 14px;
  margin-left: 10px;
`

export const FormSelect = styled(Box)`
  padding: 10px 20px 10px 15px;
  font-size: 14px;
  min-width: 95px;
  //height: 25px;
  border: 1px solid #000;
  border-radius: 6px;
  margin: 10px 0 10px 15px;

  @media (max-width: 420px) {
    min-width: 75px;
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: 90% 50%;
  background-size: 12px;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf-8, \\
<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 30'> \\
  <polygon points='0,0 50,0 25,30' style='fill:black;'/> \\
</svg>");
  
  option {
    padding: 15px;
    font-size: 16px;
  }
`
