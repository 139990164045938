import React from 'react';
import styled, {css, useTheme} from "styled-components";
import {Box, Flex, Text, Heading} from "rebass/styled-components";
import {useTranslation} from "next-i18next";
import Image from 'next/image';

const About = ({screenWidth: width}) => {
  const theme = useTheme();
  const { t } = useTranslation('common');

  const laptopWidth = theme.breakpointsNumeric[2];
  const mobileWidth = theme.breakpointsNumeric[1];
  const smMobileWidth = theme.breakpointsNumeric[0];

  return (
    <>
      <Section
        isSmMobile={width <= smMobileWidth}
        id='section-1'
        fullWidth
        heading
        sx={{
          position: 'relative',
          maxHeight: '820px',
        }}
      >
        <PageContent>
          <MainTitle
            as='h1'
            fontFamily='secondary'
            isLaptop={width <= laptopWidth}
            isMobile={width <= mobileWidth}
            isSmMobile={width <= smMobileWidth}
          >
            {t('aboutUsPageTitle')}
          </MainTitle>
        </PageContent>

        <ImageOverlay/>
        <ImageContainer
          sx={{
            minHeight: width <= mobileWidth ? 325 : 820
          }}
        >
          <CarHeaderImage
            priority
            alt='dog'
            layout='fill'
            height={width <= mobileWidth ? 500 : 800}
            src='/static-web/images/DOG-min.png'
            title=''
          />
        </ImageContainer>
      </Section>

      <Section
        isSmMobile={width <= smMobileWidth}
        id='section-2'
        fullWidth
      >
        <PageContent>
          <Item>
            <ItemTitle>
              <Image width={64} height={64} alt='about Carfind' src='/static-web/images/icons/about/1.svg' />
              <TitleText>{t('aboutUsPageAboutTitle')}</TitleText>
            </ItemTitle>
            <ItemContent>
              {t('aboutUsPageAboutContent')}
            </ItemContent>
          </Item>
          <Item>
            <ItemTitle>
              <HorizontalLine />
              <Image width={64} height={64} alt='Carfind philosophy' src='/static-web/images/icons/about/2.svg' />
              <TitleText>{t('aboutUsPagePhilosophyTitle')}</TitleText>
            </ItemTitle>
            <ItemContent>
              {t('aboutUsPagePhilosophyContent')}
            </ItemContent>
          </Item>
          <Item>
            <ItemTitle>
              <HorizontalLine />
              <Image width={64} height={64} alt='Carfind goal' src='/static-web/images/icons/about/3.svg' />
              <TitleText>{t('aboutUsPageAimTitle')}</TitleText>
            </ItemTitle>
            <ItemContent>
              {t('aboutUsPageAimContent')}
            </ItemContent>
          </Item>
          <Item>
            <ItemTitle>
              <HorizontalLine />
              <Image width={64} height={64} alt='Carfind social position' src='/static-web/images/icons/about/4.svg' />
              <TitleText>{t('aboutUsPagePositionTitle')}</TitleText>
            </ItemTitle>
            <ItemContent>
              {t('aboutUsPagePositionContent')}
            </ItemContent>
          </Item>
        </PageContent>
      </Section>
    </>
  );
};

const HorizontalLine = styled(Box)`
  width: 300px;
  height: 2px;
  background: #000;
  margin: 75px 0;
`

const TitleText = styled(Heading).attrs(() => ({
  fontFamily: 'secondary',
}))`
  color: #000;
  font-size: 28px;
  margin-top: 25px;
`

const ItemContent = styled(Text).attrs(() => ({
  fontFamily: 'primary'
}))`
  text-align: justify;
  font-size: 16px;
  width: 66%;
  @media (max-width: 900px) {
    width: 100%;
  }
`

const ItemTitle = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  img {
    width: 64px;
  }
`

const Item = styled(Flex)`
  flex-direction: column;
  align-items: center;
  //:not(:last-child) {
  //  margin-bottom: 50px;
  //}
`

const MainTitle = styled(Heading)`
  position: absolute;
  color: #fff;
  left: 50px;
  max-width: 55%;
  text-transform: uppercase;
  padding: 0;
  font-size: 48px;
  line-height: 60px;
  top: 225px;
  
  @media (max-width: 1600px) {
    left: 13.5%;
  }
  
  @media (max-width: 1200px) {
    left: 55px;
  }

  @media (max-width: 600px) {
    left: 50%;
  }

  ${({isLaptop}) => isLaptop && css`
    padding: 12px;
    font-size: 36px;
    top: 175px;
    max-width: 80%;
  `}

  ${({isMobile}) => isMobile && css`
    padding: 0;
    font-size: 32px;
    line-height: 46px;
    top: 100px;
  `}

  ${({isSmMobile}) => isSmMobile && css`
    font-size: 24px;
    max-width: 100%;
    text-align: center;

    left: 50%;
    width: 100%;
    transform: translateX(-50%);
  `}
`;

const CarHeaderImage = styled(Image)`
  width: 100%;
  max-height: 820px;
  object-fit: cover;
  min-height: 325px;
  position: relative;
  @media (max-width: 1000px) {
    min-height: 325px;
  }
`

const ImageOverlay = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  //background: rgba(0, 0, 0, 0.6);
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 50%, rgb(98, 98, 98) 100%);
  min-height: 325px;
`

const ImageContainer = styled(Box)`
  width: 100%;
  height: fit-content;
`

const Section = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({heading}) => heading ? 0 : '75px 50px'};
  max-width: ${({fullWidth, theme}) => fullWidth ? '100%' : theme.breakpoints[2]};
  height: ${(({staticHeight}) => staticHeight ? `${staticHeight}px` : 'fit-content')};
  background: #fff;

  :not(:first-child) {
    margin: 0 auto;
  }

  ${({isSmMobile}) => isSmMobile && css`
    padding: ${({heading}) => heading ? 0 : '20px 20px 30px'};
  `}
`

const PageContent = styled(Flex)`
  padding: 0;
  width: 100%;
  max-width: 1600px;
  position: relative;
  z-index: 99;
  flex-direction: column;
`


export default About;
