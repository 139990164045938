import React from 'react';
import PropTypes from 'prop-types';
import styled, {css, useTheme} from "styled-components";
import {Box, Flex, Heading, Text} from "rebass/styled-components";
import {sectionHeadingStyles} from "../Home/Home";
import {useTranslation} from "next-i18next";
import Image from 'next/image';
import loadable from '@loadable/component'

const Calculator = loadable(() => import('./components/CustomsClearanceCalculator/CustomsClearanceCalculator'))

const CalculatorComponent = ({screenWidth: width}) => {

  const theme = useTheme();
  const { t } = useTranslation('common');

  const laptopWidth = theme.breakpointsNumeric[2];
  const mobileWidth = theme.breakpointsNumeric[1];
  const smMobileWidth = theme.breakpointsNumeric[0];

  return (
    <>
      <Section
        isSmMobile={width <= smMobileWidth}
        id='section-1'
        fullWidth
        heading
        sx={{
          position: 'relative',
          maxHeight: '820px',
          // minHeight: '575px'
        }}
      >
        <PageContent>
          <MainTitle
            as='h1'
            fontFamily='secondary'
            isLaptop={width <= laptopWidth}
            isMobile={width <= mobileWidth}
            isSmMobile={width <= smMobileWidth}
          >
            {t('calculatorPageTitle')}
          </MainTitle>
          <SubTitle
            fontFamily='primary'
            color='text.primary'
          >
            {t('calculatorPageSubTitle')}
          </SubTitle>
        </PageContent>

        <ImageOverlay/>
        <ImageContainer
          sx={{
            minHeight: width <= mobileWidth ? 325 : 800
          }}
        >
          <CarHeaderImage
            priority
            alt='car-calculator'
            layout='fill'
            height={width <= mobileWidth ? 500 : 800}
            src='/static-web/images/bg-calculator-min.png'
          />
        </ImageContainer>
      </Section>

      <Section
        isSmMobile={width <= smMobileWidth}
        id='section-2'
        fullWidth
      >
        <Text
          sx={{
            ...sectionHeadingStyles
          }}
        >
          <Heading
            variant='heading.xl'
            color='text.secondary'
            fontFamily='secondary'
          >
            {t('calculatorSectionTitle')}
          </Heading>
        </Text>
        <PageContent>
          <Calculator />
        </PageContent>
      </Section>
    </>
  );
};

const SubTitle = styled(Heading)`
  max-width: 500px;
  line-height: 22px;
  text-transform: uppercase;
  margin-top: 25px;
  position: absolute;
  font-weight: 200;
  padding: 0;
  right: 3%;
  text-align: right;
  font-size: 18px;
  top: 500px;
  
  @media (max-width: 1600px) {
    right: 12%;
    width: 400px;
  }

  @media (max-width: 1200px) {
    padding: 12px;
    right: 45px;
    top: 500px;
  }

  @media (max-width: 900px) {
    padding: 0;
    right: 5%;
    font-size: 16px;
    top: 225px;
  }

  @media (max-width: 600px) {
    //border-top: 1px solid #fff;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    font-size: 12px;
    top: 225px;

    left: 50%;
    width: 100%;
    padding: 10px 36px 0;
    transform: translateX(-50%);
  }
`;

const MainTitle = styled(Heading)`
  max-width: 560px;
  font-weight: bold;
  position: absolute;
  color: #fff;
  right: 3%;
  text-align: right;
  text-transform: uppercase;
  padding: 0;
  font-size: 58px;
  line-height: 60px;
  top: 225px;

  @media (max-width: 1600px) {
    right: 12%;
  }

  @media (max-width: 1200px) {
    right: 45px;
    padding: 12px;
    top: 175px;
  }

  @media (max-width: 900px) {
    right: 5%;
    padding: 0;
    font-size: 46px;
    line-height: 46px;
    top: 50px;
  }

  @media (max-width: 600px) {
    font-size: 28px;
    max-width: 100%;
    text-align: center;

    left: 50%;
    width: 100%;
    transform: translateX(-50%);
  }
`;

const CarHeaderImage = styled(Image)`
  background: #F6F7F9;
  width: 100%;
  max-height: fit-content;
  object-fit: cover;
  min-height: 325px;
  max-height: 820px;
  position: relative;
  left: 0;
  top: 0;

  //@media (max-width: 1600px) {
  //  height: 750px;
  //}
  //
  //@media (max-width: 1000px) {
  //  min-height: 200px;
  //}
  //
  //@media (max-width: 700px) {
  //  min-height: 185px;
  //}
  //
  //@media (max-width: 600px) {
  //  height: 550px;
  //}
`

const ImageOverlay = styled(Box)`
  position: absolute;
  width: 100%;
  height: calc(100% - 3px);
  background: rgba(0, 0, 0, 0.4);
  min-height: 325px;
  z-index: 1;

  //@media (max-width: 1600px) {
  //  height: 750px;
  //}
  //
  //@media (max-width: 600px) {
  //  height: calc(100% - 3px);
  //}
`

const ImageContainer = styled(Box)`
  width: 100%;
  height: fit-content;
  background: #F6F7F9;
`

const Section = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({heading}) => heading ? 0 : '75px 50px'};
  max-width: ${({fullWidth, theme}) => fullWidth ? '100%' : theme.breakpoints[2]};
  height: ${(({staticHeight}) => staticHeight ? `${staticHeight}px` : 'fit-content')};

  :not(:first-child) {
    margin: 0 auto;
  }

  ${({isSmMobile}) => isSmMobile && css`
    padding: ${({heading}) => heading ? 0 : '20px 20px 30px'};
  `}
`

const PageContent = styled(Flex)`
  padding: 0;
  width: 100%;
  max-width: 1600px;
  position: relative;
  z-index: 99;
  flex-wrap: wrap;
  
  @media (max-width: 900px) {
    flex-direction: column-reverse;
  }
`

CalculatorComponent.propTypes = {
  screenWidth: PropTypes.number
};

export default CalculatorComponent;
