import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Button, Flex, Text, Heading} from "rebass/styled-components";
import styled, {css, useTheme} from "styled-components";
import {FaBars as FilterListIcon} from '@react-icons/all-files/fa/FaBars';
import { Oval } from  'react-loader-spinner'
import {useRouter} from "next/router";
import {
  carBodyTypes, carSortingOptions,
  carStatuses,
  fuels,
  transmissions,
  wheelDriveTypes
} from "../../constants/carAttributesMap";
import useCarMapping from "../../hooks/useCarMapping";
import useThrottle from "../../hooks/useThrottle";
import toTitleCase from "../../util/toTitlecase";
import {useTranslation, i18n} from "next-i18next";
import axios from "axios";
import {carsListEndpoint} from "../../constants/api";
import Image from 'next/image';
import Collapsible from 'react-collapsible';
import loadable from '@loadable/component';
import {FormSubmitButton} from "../Calculator/components/CustomsClearanceCalculator/components/Shared.styles";


const CarItem = loadable(() => import('../Home/components/TradeCarsSection/components/CarItem'));
const Car = loadable(() => import('./components/Car'));

const FilterWrapperComponent = ({showAccordionFilters, children}) => {
  const {t} = useTranslation('common');

  const filtersTrigger = (
    <Flex
      justifyContent='space-between'
      width='100%'
      sx={{
        '&:hover': {
          cursor: 'pointer'
        }
      }}
    >
      <Text variant='heading.md' color='primary'>{t('cataloguePageFiltersLabel')}</Text>
      <FilterListIcon/>
    </Flex>
  )

  return (
    showAccordionFilters ?
      <Collapsible trigger={filtersTrigger}>
        <Box mt={4}>
          {children}
        </Box>
      </Collapsible>
      :
      <Flex justifyContent='space-between' width='100%'>
        {children}
      </Flex>
  )
}

const Catalogue = props => {
  const {screenWidth: width, cars, brands, queryFilters = {}, mappedFilterValue} = props;

  const theme = useTheme();
  const router = useRouter();
  const {
    carId = null
  } = router.query;
  const {language: resolvedLanguage} = i18n || {};
  const mapCar = useCarMapping();
  const {t} = useTranslation('common');

  const laptopWidth = theme.breakpointsNumeric[2];
  const mobileWidth = theme.breakpointsNumeric[1];
  const smMobileWidth = theme.breakpointsNumeric[0];

  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [mappedCars, setMappedCars] = useState([]);
  const [currentModels, setCurrentModels] = useState([]);
  const [currentBrands, setCurrentBrands] = useState([]);
  const [selectedCarStatus, setSelectedCarStatus] = useState();
  const [mainTitle, setMainTitle] = useState(
    mappedFilterValue ?
      resolvedLanguage === 'ua' ? `${mappedFilterValue} з США і Канади під ключ`  : `${mappedFilterValue} из США и Канады под ключ` :
      t('cataloguePageTitle')
  );
  const [currentFilterMappedValue, setCurrentFilterMappedValue] = useState('');

  const brandSelect = useRef();
  const modelSelect = useRef();
  const transmissionSelect = useRef();
  const wheelDriveTypeSelect = useRef();
  const carBodyTypeSelect = useRef();
  const fuelSelect = useRef();

  const getCarsList = useCallback(async (url, restCars = [], reset = false) => {
    setLoading(true);
    const response = await axios.get(url, {crossDomain: true});
    const responseCarsData = response?.data?.data;
    const mappedResponseCars = responseCarsData.map(mapCar);
    const updatedCars = [
      ...restCars,
      ...mappedResponseCars,
    ]
    setMappedCars(updatedCars);
    let updatedUrl = ``;
    if (reset) {
      // reset
      window.history.replaceState({ ...window.history.state, as: '/catalogue', url: '/catalogue' }, '', '/catalogue');
    } else {
      Object.keys(selectedFilterValues).map(((key, index) => {
        updatedUrl += `${index === 0 ? '' : '/'}${key}=${selectedFilterValues[key]}`
      }))
      const finalUrl = resolvedLanguage === 'ua' ? `catalogue/${updatedUrl}`: `ru/catalogue/${updatedUrl}`
      window.history.replaceState({ ...window.history.state, as: finalUrl, url: finalUrl }, '', finalUrl);
    }

    setLoading(false);
  }, [mapCar, selectedFilterValues, resolvedLanguage]);

  const getChinaCars = useCallback(async () => {
    setLoading(true);
    setSelectedCarStatus(carStatuses[4].value)
    const response = await axios.get(`${carsListEndpoint}&is_china=1`, {crossDomain: true});
    const responseCarsData = response?.data?.data;
    const mappedResponseCars = responseCarsData.map(mapCar);
    const updatedCars = [
      ...mappedResponseCars,
    ]
    setMappedCars(updatedCars);
    setLoading(false)
  }, []);

  const getTeslaCars = useCallback(async () => {
    setLoading(true);
    setSelectedCarStatus(carStatuses[3].value)
    const response = await axios.get(`${carsListEndpoint}&brand=Tesla&status=stock`, {crossDomain: true});
    const responseCarsData = response?.data?.data;
    const mappedResponseCars = responseCarsData.map(mapCar);
    const updatedCars = [
      ...mappedResponseCars,
    ]
    setMappedCars(updatedCars);
    setLoading(false)
  }, []);

  const handleSorting = useCallback((event) => {
    setLoading(true);
    const index = event.target.value;
    const {fieldName, type} = carSortingOptions[index].value;
    const sortedCars = [...mappedCars]
      .sort((a, b) => {
        if ( a[fieldName] < b[fieldName] ){
          return type === 'asc' ? -1 : 1;
        }
        if ( a[fieldName] > b[fieldName] ){
          return type === 'asc' ? 1 : -1;
        }
        return 0;
      })
    router.push(router.pathname, undefined, { shallow: true })
    setMappedCars(sortedCars);
    setLoading(false);
  }, [setMappedCars, mappedCars, router, setLoading, carSortingOptions]);

  const applyFilters = useCallback(async () => {
    setLoading(true);
    setFiltersApplied(true);
    let queryParams = `&limit=100`;
    Object.keys(selectedFilterValues).forEach((key) => {
      if (selectedFilterValues.hasOwnProperty(key) && !!selectedFilterValues[key]) {
        queryParams += `&${key}=${selectedFilterValues[key]}`
      }
    })
    const translitObject = {
      fuel: fuels,
      car_body_type: carBodyTypes,
      transmission: transmissions,
      status: carStatuses,
      wheel_drive_type: wheelDriveTypes,
    }
    const filterValue = Object.keys(selectedFilterValues).map(filterKey => (
      translitObject[filterKey]?.find(f => f.value === selectedFilterValues[filterKey])?.[resolvedLanguage] || selectedFilterValues[filterKey]
    ));
    setCurrentFilterMappedValue(filterValue.join(' '));
    if (resolvedLanguage === 'ua') {
      setMainTitle(`${filterValue.join(' ')} з США та Канади`)
    } else {
      setMainTitle(`${filterValue.join(' ')} из США и Канады`)
    }
    getCarsList(`${carsListEndpoint}${queryParams}`)
  }, [setCurrentFilterMappedValue, selectedFilterValues, resolvedLanguage, getCarsList]);

  const resetFiltersAndCars = useCallback(async () => {
    setLoading(true);
    setFiltersApplied(false);
    brandSelect.current.value = t('cataloguePageFilterTypeBrand');
    modelSelect.current.value = t('cataloguePageFilterTypeModel');
    transmissionSelect.current.value = t('cataloguePageFilterTypeTransmission');
    wheelDriveTypeSelect.current.value = t('cataloguePageFilterTypeWheelDriveType');
    carBodyTypeSelect.current.value = t('cataloguePageFilterTypeBodyType');
    fuelSelect.current.value = t('cataloguePageFilterTypeFuelType');
    setSelectedFilterValues({});
    setCurrentModels([]);
    setSelectedCarStatus(null);
    setMainTitle(t('cataloguePageTitle'));
    getCarsList(`${carsListEndpoint}&limit=20`, [], true);
  }, [t, setMainTitle, getCarsList, setLoading, setFiltersApplied, setSelectedFilterValues, setCurrentModels]);

  const handleFilterSelect = useCallback((event) => {
    const {name, value} = event.target;
    if (name === 'brand') {
      let filteredModels = [];
      Object.keys(brands).map(key => {
        if (key.toLowerCase().trim() === value.toLowerCase()) {
          filteredModels = brands[key].sort();
        }
      });
      setCurrentModels(filteredModels);
    }
    if (!value && selectedFilterValues[name]) { // remove value which existed
      setSelectedFilterValues(
        Object
          .keys(selectedFilterValues)
          .filter(key => name !== key)
          .map(key => selectedFilterValues[key])
      )
    } else {
      setSelectedFilterValues({
        ...selectedFilterValues,
        [name]: value,
      })
    }
  }, [mappedCars, selectedFilterValues, setSelectedFilterValues]);

  const setRangeInputValuesDebounce = useThrottle((event) => {
    handleFilterSelect(event)
  }, 100);

  const handleRangeInputChange = useCallback((event) => {
    setRangeInputValuesDebounce(event);
  }, [setRangeInputValuesDebounce]);

  const loadMoreCars = useCallback(async () => {
    const url = `${carsListEndpoint}&skip=${mappedCars.length + 1}&limit=${20}`
    getCarsList(url, mappedCars)
  }, [getCarsList, mappedCars]);

  const onStatusButtonClick = useCallback((value) => {
    handleFilterSelect({
      target: {
        name: 'status',
        value: value
      }
    })
    setSelectedCarStatus(value)
  }, [handleFilterSelect]);

  useEffect(() => {
    if (brands) {
      setCurrentBrands([...Object.keys(brands).map(b => toTitleCase(b.trim().toLowerCase()))].sort());
    }
  }, [brands]);

  useEffect(() => {
    if (cars.length > 0 && mappedCars.length === 0 && !filtersApplied) {
      setMappedCars(cars.map(mapCar));
    }
  }, [filtersApplied, setMappedCars, mappedCars, cars])

  return (
    <>
      <Section
        isSmMobile={width <= smMobileWidth}
        id='section-1'
        fullWidth
        heading
        sx={{
          position: 'relative',
          // minHeight: '750px'
        }}
      >
        <PageContent title>
          <MainTitle
            as='h1'
            fontFamily='secondary'
            isLaptop={width <= laptopWidth}
            isMobile={width <= mobileWidth}
            isSmMobile={width <= smMobileWidth}
          >
            {mainTitle}
          </MainTitle>
        </PageContent>

        <ImageOverlay/>
        <ImageContainer
          sx={{
            minHeight: width <= mobileWidth ? 500 : 800
          }}
        >
          <CarHeaderImage
            priority
            alt='cars-catalogue'
            layout='fill'
            height={width <= mobileWidth ? 500 : 800}
            src='/static-web/images/bg-catalogue-min.png'
          />
        </ImageContainer>
      </Section>

      {/*handles route catalogue/ */}
      {!carId && (
        <>
          <Section
            isSmMobile={width <= smMobileWidth}
            id='section-2'
            fullWidth
          >
            <PageContent>
              <FilterWrapperComponent
                showAccordionFilters={width <= mobileWidth}
              >
                <Flex flexDirection='column'>
                  <TypeSelector>
                    <Flex
                      sx={{
                        border: '2px solid #000',
                        borderRadius: 12
                      }}
                    >
                      <SelectButton
                        active={selectedCarStatus === carStatuses[1].value}
                        isSmMobile={width <= smMobileWidth}
                        variant='outlined'
                        color='text.secondary'
                        onClick={() => {
                          onStatusButtonClick(carStatuses[1].value)
                        }}
                        sx={{
                          borderTop: 'none',
                          borderBottom: 'none',
                          borderLeft: 'none',
                          alignSelf: 'center',
                          borderRadius: '10px 0 0 10px',
                        }}
                      >
                        {t('cataloguePageFilterTypeOrder')}
                      </SelectButton>
                      <SelectButton
                        active={selectedCarStatus === carStatuses[0].value}
                        isSmMobile={width <= smMobileWidth}
                        variant='borderless'
                        color='text.secondary'
                        onClick={() => {
                          onStatusButtonClick(carStatuses[0].value)
                        }}
                        sx={{
                          borderTop: 'none',
                          borderBottom: 'none',
                          '&:hover': {
                            background: '#000',
                            color: '#fff'
                          }
                        }}
                      >
                        {t('cataloguePageFilterTypeStock')}
                      </SelectButton>
                      <SelectButton
                        active={selectedCarStatus === carStatuses[2].value}
                        isSmMobile={width <= smMobileWidth}
                        variant='outlined'
                        color='text.secondary'
                        onClick={() => {
                          onStatusButtonClick(carStatuses[2].value)
                        }}
                        sx={{
                          borderTop: 'none',
                          borderBottom: 'none',
                          alignSelf: 'center',
                        }}
                      >
                        {t('cataloguePageFilterTypeShipping')}
                      </SelectButton>
                      <SelectButton
                        active={selectedCarStatus === carStatuses[4].value}
                        isSmMobile={width <= smMobileWidth}
                        variant='outlined'
                        color='text.secondary'
                        onClick={() => {
                          getChinaCars();
                        }}
                        sx={{
                          borderRight: 'none',
                          borderLeft: 'none',
                          borderTop: 'none',
                          borderBottom: 'none',
                          alignSelf: 'center',
                          borderRadius: '0 10px 10px 0',
                        }}
                      >
                        {t('cataloguePageFilterTypeChina')}
                      </SelectButton>
                      {/*<SelectButton*/}
                      {/*  active={selectedCarStatus === carStatuses[3].value}*/}
                      {/*  isSmMobile={width <= smMobileWidth}*/}
                      {/*  variant='borderless'*/}
                      {/*  color='text.secondary'*/}
                      {/*  onClick={() => {*/}
                      {/*    getTeslaCars();*/}
                      {/*  }}*/}
                      {/*  sx={{*/}
                      {/*    borderTop: 'none',*/}
                      {/*    borderBottom: 'none',*/}
                      {/*    borderRadius: '0 10px 10px 0',*/}
                      {/*    '&:hover': {*/}
                      {/*      background: '#000',*/}
                      {/*      color: '#fff'*/}
                      {/*    }*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  Tesla*/}
                      {/*</SelectButton>*/}
                    </Flex>
                  </TypeSelector>

                  <SelectGroup>
                    <CustomSelect ref={brandSelect} name='brand' onChange={handleFilterSelect}>
                      <option selected className='holder'>{t('cataloguePageFilterTypeBrand')}</option>
                      {currentBrands.map((b) => (
                        <option key={`ec-${b}`} value={b}>{b}</option>
                      ))}
                    </CustomSelect>

                    <CustomSelect ref={modelSelect} disabled={!currentModels.length} name='model'
                                  onChange={handleFilterSelect}>
                      <option selected className='holder'>{t('cataloguePageFilterTypeModel')}</option>
                      {currentModels.map((m) => (
                        <option key={`ec-${m}`} value={m}>{m}</option>
                      ))}
                    </CustomSelect>
                  </SelectGroup>

                  <SelectGroup>
                    <CustomSelect ref={wheelDriveTypeSelect} name='wheel_drive_type' onChange={handleFilterSelect}>
                      <option selected className='holder'>{t('cataloguePageFilterTypeWheelDriveType')}</option>
                      {wheelDriveTypes.map(wdt => (
                        <option key={`ec-${wdt.value}`} value={wdt.value}>{wdt[resolvedLanguage]}</option>
                      ))}
                    </CustomSelect>

                    <CustomSelect ref={transmissionSelect} name='transmission' onChange={handleFilterSelect}>
                      <option selected className='holder'>{t('cataloguePageFilterTypeTransmission')}</option>
                      {transmissions.map(t => (
                        <option key={`ec-${t.value}`} value={t.value}>{t[resolvedLanguage]}</option>
                      ))}
                    </CustomSelect>
                  </SelectGroup>

                  <SelectGroup>
                    <CustomSelect ref={carBodyTypeSelect} name='car_body_type' onChange={handleFilterSelect}>
                      <option selected className='holder'>{t('cataloguePageFilterTypeBodyType')}</option>
                      {carBodyTypes.map(cbt => (
                        <option key={`ec-${cbt.value}`} value={cbt.value}>{cbt[resolvedLanguage]}</option>
                      ))}
                    </CustomSelect>

                    <CustomSelect ref={fuelSelect} name='fuel' onChange={handleFilterSelect}>
                      <option selected className='holder'>{t('cataloguePageFilterTypeFuelType')}</option>
                      {fuels.map(f => (
                        <option key={`ec-${f.value}`} value={f.value}>{f[resolvedLanguage]}</option>
                      ))}
                    </CustomSelect>
                  </SelectGroup>

                  {width > mobileWidth && (
                    <Flex width='100%'>
                      <FormSubmitButton
                        onClick={applyFilters}
                        variant='outlined'
                        disabled={isSubmitting}
                        sx={{
                          textTransform: 'uppercase',
                          fontSize: 12,
                          fontFamily: 'primary.bold',
                        }}
                      >
                        {t('cataloguePageFilterButtonSubmit')}
                      </FormSubmitButton>
                      <FormSubmitButton
                        onClick={resetFiltersAndCars}
                        variant='borderless'
                        disabled={isSubmitting}
                        sx={{
                          textTransform: 'uppercase',
                          fontSize: 12,
                          fontFamily: 'primary.bold'
                        }}
                      >
                        {t('cataloguePageFilterButtonReset')}
                      </FormSubmitButton>
                    </Flex>
                  )}
                </Flex>

                {width <= mobileWidth && (
                  <Divider/>
                )}

                <RangeInputsWrapper>
                  <InputGroup>
                    <InputLabel>{t('cataloguePageFilterTypeEngineCapacity')}</InputLabel>
                    <RangeInput
                      onChange={(event) => {
                        handleRangeInputChange({
                          target: {
                            name: 'engine_capacity_from',
                            value: event.target.value
                          }
                        })
                      }}
                      placeholder={t('catalogueRangeInputFromLabel')}
                      type='number'/>
                    <RangeInput
                      onChange={(event) => {
                        handleRangeInputChange({
                          target: {
                            name: 'engine_capacity_to',
                            value: event.target.value
                          }
                        })
                      }}
                      placeholder={t('catalogueRangeInputToLabel')}
                      type='number'/>
                  </InputGroup>

                  <InputGroup>
                    <InputLabel>{t('cataloguePageFilterTypeMileage')}</InputLabel>
                    <RangeInput
                      onChange={(event) => {
                        handleRangeInputChange({
                          target: {
                            name: 'mileage_from',
                            value: event.target.value
                          }
                        })
                      }}
                      placeholder={t('catalogueRangeInputFromLabel')}
                      type='number'/>
                    <RangeInput
                      onChange={(event) => {
                        handleRangeInputChange({
                          target: {
                            name: 'mileage_to',
                            value: event.target.value
                          }
                        })
                      }}
                      placeholder={t('catalogueRangeInputToLabel')}
                      type='number'/>
                  </InputGroup>

                  <InputGroup>
                    <InputLabel>{t('cataloguePageFilterTypePrice')} ($)</InputLabel>
                    <RangeInput
                      onChange={(event) => {
                        handleRangeInputChange({
                          target: {
                            name: 'price_from',
                            value: event.target.value
                          }
                        })
                      }}
                      placeholder={t('catalogueRangeInputFromLabel')}
                      type='number'/>
                    <RangeInput
                      onChange={(event) => {
                        handleRangeInputChange({
                          target: {
                            name: 'price_to',
                            value: event.target.value
                          }
                        })
                      }}
                      placeholder={t('catalogueRangeInputToLabel')}
                      type='number'/>
                  </InputGroup>

                  <InputGroup>
                    <InputLabel>{t('cataloguePageFilterTypeYear')}</InputLabel>
                    <RangeInput
                      onChange={(event) => {
                        handleRangeInputChange({
                          target: {
                            name: 'year_from',
                            value: event.target.value
                          }
                        })
                      }}
                      placeholder={t('catalogueRangeInputFromLabel')}
                      type='number'/>
                    <RangeInput
                      onChange={(event) => {
                        handleRangeInputChange({
                          target: {
                            name: 'year_to',
                            value: event.target.value
                          }
                        })
                      }}
                      placeholder={t('catalogueRangeInputToLabel')}
                      type='number'/>
                  </InputGroup>

                  <InputGroup>
                    <InputLabel>{t('cataloguePageFilterTypeBatteryCapacity')}</InputLabel>
                    <RangeInput
                      onChange={(event) => {
                        handleRangeInputChange({
                          target: {
                            name: 'battery_capacity_from',
                            value: event.target.value
                          }
                        })
                      }}
                      placeholder={t('catalogueRangeInputFromLabel')}
                      type='number'/>
                    <RangeInput
                      onChange={(event) => {
                        handleRangeInputChange({
                          target: {
                            name: 'battery_capacity_to',
                            value: event.target.value
                          }
                        })
                      }}
                      placeholder={t('catalogueRangeInputToLabel')}
                      type='number'/>
                  </InputGroup>
                </RangeInputsWrapper>

                {width <= mobileWidth && (
                  <>
                    <Flex width='100%' flexDirection='column' my={3} justifyContent='center'>
                      <FormSubmitButton
                        onClick={applyFilters}
                        variant='outlined'
                        disabled={isSubmitting}
                        sx={{
                          textTransform: 'uppercase',
                          fontSize: 12,
                          fontFamily: 'primary.bold'
                        }}
                      >
                        {t('cataloguePageFilterButtonSubmit')}
                      </FormSubmitButton>
                      <FormSubmitButton
                        onClick={resetFiltersAndCars}
                        variant='borderless'
                        disabled={isSubmitting}
                        sx={{
                          textTransform: 'uppercase',
                          fontSize: 12,
                          fontFamily: 'primary.bold'
                        }}
                      >
                        {t('cataloguePageFilterButtonReset')}
                      </FormSubmitButton>
                    </Flex>
                  </>
                )}
              </FilterWrapperComponent>
            </PageContent>
          </Section>

          <Section
            isSmMobile={width <= smMobileWidth}
            id='section-3'
            fullWidth
          >
            <PageContent>
              <Flex justifyContent="flex-end" width='100%'>
                <CustomSelect onChange={handleSorting}>
                  <option selected className='holder'>{t('cataloguePageSortingLabel')}</option>
                  {carSortingOptions.map((so, index) => (
                    <option key={`ec-${so.value.fieldName}${so.value.type}`} value={index}>{so[resolvedLanguage]}</option>
                  ))}
                </CustomSelect>
              </Flex>

              {isLoading && (
                <Flex
                  sx={{
                    height: '500px',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Oval
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                  />
                </Flex>
              )}

              {!isLoading && mappedCars.length > 0 && (
                <>
                  <CarsWrapper>
                    {mappedCars.map(car => (
                      <CarItem
                        key={car.uuid}
                        sx={{
                          margin: '0 !important',
                          maxWidth: '100% !important',
                          background: '#fff'
                        }}
                        screenWidth={width}
                        car={car}
                      />
                    ))}
                  </CarsWrapper>

                  {!filtersApplied && Object.keys(queryFilters).length === 0 && (
                    <Flex width={'100%'} justifyContent='center' mt={8}>
                      <Button
                        color='primary'
                        variant="outlined"
                        onClick={loadMoreCars}
                        sx={{
                          borderRadius: '12px'
                        }}
                      >
                        {t('cataloguePageLoadMoreButton')}
                      </Button>
                    </Flex>
                  )}
                </>
              )}

              {filtersApplied && mappedCars.length === 0 && (
                <>
                  <Text
                    sx={{
                      fontFamily: 'primary',
                      fontSize: '18px',
                      width: '100%',
                      textAlign: 'center',
                      mt: 5
                    }}
                  >
                    {t('cataloguePageNoResultsText')}
                  </Text>
                  <Button
                    onClick={resetFiltersAndCars}
                    variant='contained'
                    sx={{
                      margin: '15px auto',
                      borderRadius: '12px'
                    }}
                  >
                    {t('cataloguePageFilterButtonReset')}
                  </Button>
                </>
              )}
            </PageContent>
          </Section>
        </>
      )}


      {/*handles route catalogue/:carId  */}
      {carId && (
        <Car id={carId} width={width} setMainTitle={setMainTitle}/>
      )}
    </>
  );
};

const Divider = styled(Flex)`
  width: 100%;
  height: 2px;
  background: #dedede;
  margin: 15px 0;
`

const RangeInputsWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 900px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

const CarsWrapper = styled(Flex).attrs(() => ({
  mt: 4
}))`
  width: 100%;
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(5, 1fr);
  @media (max-width: 1600px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1600px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1400px) {
    gap: 30px;
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
  }
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 75px;
  }
  @media (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  @media (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 0;
  }
`

export const InputLabel = styled(Text).attrs(({sx}) => ({
  fontFamily: 'primary'
}))`
  font-size: 12px;
  min-width: 45px;
  text-transform: uppercase;
  @media (max-width: 1200px) {
    font-size: 12px;
  }
  @media (max-width: 900px) {
    font-size: 12px;
    margin-bottom: 5px;
    width: 100%;
  }
`

const RangeInput = styled.input`
  padding: 15px;
  font-size: 14px;
  min-width: 80px;
  height: 38px;
  border: 2px solid #000;
  border-radius: 12px;
  max-width: 95px;
  margin-left: 10px;
  background: inherit;

  @media (max-width: 900px) {
    margin-left: 0;
    margin-right: 10px;
    max-width: calc(50% - 10px);
    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 600px) {
    max-width: 100%;
    width: 100%;
    &:last-child {
      margin-right: 10px;
      margin-top: 10px;
    }
  }

  ${({type}) => type === 'number' && css`
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    -moz-appearance: textfield;
  `}
`

const InputGroup = styled(Flex)`
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  flex-wrap: nowrap;

  @media (max-width: 900px) {
    justify-content: flex-start;
    margin-top: 15px;
    flex-wrap: wrap;
    width: 50%;
    &:nth-child(even) {
      justify-content: flex-end;

      ${InputLabel} {
        margin-left: 10px;
      }
    }
  }

  @media (max-width: 600px) {
    &:nth-child(even) {
      ${RangeInput} {
        margin-left: 10px;
        margin-right: 0;
      }
    }
  }
`

const CustomSelect = styled.select`
  width: 100%;
  max-width: 275px;
  height: 40px;
  background-color: inherit;
  font-family: ${({theme}) => theme.fonts.primary};

  padding: 10px 20px 10px 15px;
  font-size: 14px;
  min-width: 95px;
  border: 2px solid #000;
  border-radius: 12px;

  margin-bottom: 15px;

  @media (max-width: 420px) {
    min-width: 75px;
  }

  &:focus {
    option:first-child {
      display: none;
    }
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: 90% 50%;
  background-size: 12px;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf-8,
<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 30'>
  <polygon points='0,0 50,0 25,30' style='fill:black;'/>
</svg>");

  option {
    padding: 15px;
    font-size: 16px;
  }
`

const SelectGroup = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  flex-wrap: nowrap;

  ${CustomSelect} {
    &:first-child {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: 10px;
    }
  }
`

const TypeSelector = styled(Flex)`
  min-width: 100%;
  justify-content: center;
  margin: 0 0 25px;
  @media (max-width: 900px) {
    justify-content: flex-start;
  }
`

const SelectButton = styled(Button)`
  min-width: 106px !important;
  // text-transform: uppercase;
  font-size: 12px;

  @media (max-width: 1200px) {
    min-width: 125px !important;
  }
  @media (max-width: 600px) {
    min-width: 50px !important;
    white-space: break-spaces;
    font-size: 10px;
  }

  ${({active}) => active && css`
    background: #000;
    color: #fff;
  `}
`

const ImageOverlay = styled(Box)`
  position: absolute;
  width: 100%;
  height: calc(100% - 3px);
  background: rgba(0, 0, 0, 0.4);
  min-height: 325px;
  z-index: 1;
`

const CarHeaderImage = styled(Image)`
  background: #F6F7F9;
  width: 100%;
  max-height: fit-content;
  object-fit: cover;
  min-height: 325px;
  max-height: 820px;
  position: relative;
  left: 0;
  top: 0;

  //@media (max-width: 1600px) {
  //  height: 750px;
  //}
  //
  //@media (max-width: 1000px) {
  //  min-height: 200px;
  //}
  //
  //@media (max-width: 700px) {
  //  min-height: 185px;
  //}
  //
  //@media (max-width: 600px) {
  //  height: 550px;
  //}
`

const MainTitle = styled(Heading)`
  font-weight: bold;
  position: absolute;
  color: #fff;
  right: calc(100% - 760px);
  width: 700px;
  text-transform: uppercase;
  padding: 0;
  font-size: 58px;
  line-height: 60px;
  top: 25%;

  @media (max-width: 1600px) {
    left: 13.5%;
  }

  @media (max-width: 1200px) {
    left: 55px;
  }

  @media (max-width: 900px) {
    left: 55px;
    font-size: 46px;
    line-height: 46px;
    width: 550px;
  }

  @media (max-width: 600px) {
    font-size: 28px;
    max-width: 100%;
    text-align: center;

    top: 25%;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
  }
`;

const PageContent = styled(Flex)`
  padding: 0;
  width: 100%;
  height: 100%;
  max-width: 1600px;
  position: relative;
  z-index: 99;
  flex-wrap: wrap;

  @media (max-width: 1600px) {
    padding: ${({theme}) => `0 ${theme.pageGutters[4]}`};
  }

  @media (max-width: 1200px) {
    padding: 0;
  }

  @media (min-width: 1600px) {
    padding: 0 25px;
  }
  ${({title}) => title && css`
    position: absolute;
  `}
`

const Section = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({heading}) => heading ? 0 : '0 50px'};
  max-width: ${({fullWidth, theme}) => fullWidth ? '100%' : theme.breakpoints[2]};
  height: ${(({staticHeight}) => staticHeight ? `${staticHeight}px` : 'fit-content')};

  :not(:first-child) {
    margin: 50px auto 0;
  }

  ${({isSmMobile}) => isSmMobile && css`
    padding: ${({heading}) => heading ? 0 : '20px 20px 30px'};
  `}

  ${({id}) => id === 'section-1' && css`
    //@media (max-width: 900px) {
    //  min-height: 550px;
    //}
  `}

  ${({id}) => id === 'section-2' && css`
    margin-top: 50px;
    background: #F6F7F9;
    padding: 0 50px 50px;
    @media (max-width: 1200px) {
      padding: 50px 50px;
    }
    @media (max-width: 900px) {
      padding: 0 25px 50px;
    }
    .Collapsible {
      width: 100%;
    }
  `}

  ${({id}) => id === 'section-3' && css`
    margin-top: 0 !important;
    padding: 50px 50px 75px;
    background: #e4e4e4;
  `}
`

const ImageContainer = styled(Box)`
  width: 100%;
  height: fit-content;
  background: #F6F7F9;
`

Catalogue.propTypes = {
  onCarsUpdate: PropTypes.func,
  width: PropTypes.number,
  cars: PropTypes.array,
};

Catalogue.defaultProps = {
  onCarsUpdate: () => {
  },
  width: 1600,
  cars: []
}

export default Catalogue;
