import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styled, {css, useTheme} from "styled-components";
import Image from "next/image";
import {Box, Flex, Heading} from "rebass/styled-components";
import {useTranslation} from "next-i18next";
import useCarMapping from "../../hooks/useCarMapping";
import toTitleCase from "../../util/toTitlecase";

const SitemapComponent = ({screenWidth: width, electricCars, allCars}) => {

  const theme = useTheme();
  const { t } = useTranslation('common');
  const mapCar = useCarMapping();

  const [mappedElectricCars, setMappedElectricCars] = useState([]);
  const [mappedCars, setMappedCars] = useState({});

  const laptopWidth = theme.breakpointsNumeric[2];
  const mobileWidth = theme.breakpointsNumeric[1];
  const smMobileWidth = theme.breakpointsNumeric[0];

  useEffect(() => {
    const mappedCars = allCars.map(mapCar);
    const carsSortedByBrand = {};
    mappedCars.map((car) => {
      const carBrand = toTitleCase(car.brand.trim());
      if (carsSortedByBrand[carBrand]) {
        carsSortedByBrand[carBrand].push(car)
      } else {
        carsSortedByBrand[carBrand] = [car];
      }
    })

    const orderedCars = Object.keys(carsSortedByBrand).sort().reduce(
      (obj, key) => {
        obj[key] = carsSortedByBrand[key];
        return obj;
      },
      {}
    );

    setMappedCars(orderedCars);

    const mappedElectricCars = electricCars.map(mapCar);
    const sortedCars = mappedElectricCars.sort((a, b) => a.brand > b.brand ? 1 : -1);
    setMappedElectricCars([...sortedCars]);
  }, [electricCars, allCars]);

  return (
    <>
      <Section
        isSmMobile={width <= smMobileWidth}
        id='section-1'
        fullWidth
        heading
        sx={{
          position: 'relative',
          maxHeight: '820px',
          overflow: 'hidden'
        }}
      >
        <PageContent title>
          <MainTitle
            as='h1'
            fontFamily='secondary'
            isLaptop={width <= laptopWidth}
            isMobile={width <= mobileWidth}
            isSmMobile={width <= smMobileWidth}
          >
            {t('sitemap')}
          </MainTitle>
        </PageContent>

        <ImageOverlay/>
        <ImageContainer
          sx={{
            minHeight: width <= mobileWidth ? 500 : 800
          }}
        >
          <CarHeaderImage
            priority
            alt='electric-cars-tesla'
            layout='fill'
            height={width <= mobileWidth ? 500 : 800}
            src='/static-web/images/contacts/car-header.jpg'
          />
        </ImageContainer>
      </Section>

      <Section>
        <LinksWrapper>
          <StyledLink href='about'>
            {t('menuAbout')}
          </StyledLink>
          <StyledLink href='services'>
            {t('menuServices')}
          </StyledLink>
          <StyledLink href='calculator'>
            {t('menuCalculator')}
          </StyledLink>
          <StyledLink href='contacts'>
            {t('menuContacts')}
          </StyledLink>

          <StyledLink href='electric-cars'>
            {t('menuElectricCars')}
          </StyledLink>

          {mappedElectricCars.map(car => (
            <StyledLink key={car.uuid} href={`electric-cars/${car.uuid}`} isChild level={2}>
              {toTitleCase(car.brand)} {toTitleCase(car.model)} {toTitleCase(car.trim)}
            </StyledLink>
          ))}

          <StyledLink href='catalogue'>
            {t('menuCatalogue')}
          </StyledLink>

          {Object.keys(mappedCars).map(arrayByBrand => {
            return (
              <>
                <StyledLink key={`brand-${arrayByBrand}`} href={`/catalogue/brand=${arrayByBrand}`} isChild level={1}>
                  {arrayByBrand}
                </StyledLink>
                {mappedCars[arrayByBrand].map(ec => (
                  <StyledLink key={ec.uuid} href={`catalogue/cars/${ec.uuid}`} isChild level={2}>
                    {toTitleCase(ec.brand)} {toTitleCase(ec.model)} {toTitleCase(ec.trim)}
                  </StyledLink>
                ))}
              </>
            )
          })}

        </LinksWrapper>
      </Section>
    </>
  );
};

const LinksWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
`

const StyledLink = styled.a`
  width: fit-content;
  color: #000;
  display: list-item; /* This has to be "list-item"                                          */
  margin: 8px 0 8px 1em;
  font-size: 18px;
  font-weight: bold;

  transition: all 0.2s ease-in-out;

  &:hover {
    color: rgba(0, 0, 0, 0.6);
  }

  ${({isChild, level}) => isChild && css`
    margin-left: ${level === 1 ? '2.2em' : '3.7em'};
    font-weight: ${level === 1 ? 'bold' : 'normal'};
    font-size: ${level === 1 ? '16px' : '14px'};
  `}
`

const CarHeaderImage = styled(Image)`
  width: 100%;
  max-height: 820px;
  object-fit: cover;
  min-height: 325px;
  position: relative;
  @media (max-width: 1000px) {
    min-height: 325px;
  }
`

const ImageOverlay = styled(Box)`
  position: absolute;
  width: 100%;
  height: calc(100% - 2px);
  z-index: 99;
  background: rgba(0, 0, 0, 0.4);
  min-height: 325px;
`

const ImageContainer = styled(Box)`
  width: 100%;
  height: fit-content;
`

const MainTitle = styled(Heading)`
  font-weight: bold;
  position: absolute;
  color: #fff;
  right: 3%;
  max-width: 100%;
  text-transform: uppercase;
  padding: 0 60px;
  font-size: 58px;
  line-height: 60px;
  top: 70%;

  @media (max-width: 1600px) {
    left: 9%;
  }

  //@media (max-width: 1200px) {
  //  right: 45px;
  //}

  ${({isLaptop}) => isLaptop && css`
    padding: 12px;
    top: 175px;
  `}

  ${({isMobile}) => isMobile && css`
    padding: 0;
    font-size: 36px;
    line-height: 46px;
    top: 100px;
  `}

  ${({isSmMobile}) => isSmMobile && css`
    font-size: 28px;
    max-width: 100%;
    text-align: center;

    left: 50% !important;
    width: 100%;
    transform: translateX(-50%);
  `}
`;

const PageContent = styled(Flex)`
  padding: 0;
  width: 100%;
  max-width: 1600px;
  position: relative;
  z-index: 100;
  flex-direction: column;
  
  @media (max-width: 1600px) {
    padding: ${({ theme }) => `0 ${theme.pageGutters[4]}`};
  }

  @media (max-width: 1200px) {
    padding: 0;
  }
  ${({title}) => title && css`
    position: absolute;
    height: 100%;
  `}
`

const Section = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({heading}) => heading ? 0 : '75px 50px'};
  max-width: ${({fullWidth, theme}) => fullWidth ? '100%' : theme.breakpoints[2]};
  height: ${(({staticHeight}) => staticHeight ? `${staticHeight}px` : 'fit-content')};
  background: ${({bgColor}) => bgColor ? bgColor : '#fff'};

  :not(:first-child) {
    margin: 0 auto;
  }

  ${({isSmMobile}) => isSmMobile && css`
    padding: ${({heading}) => heading ? 0 : '20px 20px 30px'};
  `}
`

SitemapComponent.propTypes = {
  width: PropTypes.number,
  electricCars: PropTypes.array,
  allCars: PropTypes.array
};

SitemapComponent.defaultProps = {
  width: 0,
  electricCars: [],
  allCars: [],
}

export default SitemapComponent;
