import React, {useEffect, useState} from 'react';
import "react-image-gallery/styles/css/image-gallery.css";
import axios from 'axios';
import {Box, Heading, Flex, Text} from "rebass/styled-components";
import {PageContent, Section, sectionHeadingStyles} from "../Home/Home";
import Image from "next/image";
import styled, {useTheme} from "styled-components";
import {useTranslation} from "next-i18next";
import {apiPublicBaseUrl, carFindLiveEndpoint} from "../../constants/api";
import dynamic from "next/dynamic";

const ImageGallery = dynamic(() => import('react-image-gallery'), {
  ssr: false,
});

const CarFindLive = ({screenWidth: width}) => {
  const theme = useTheme();

  const { t } = useTranslation('common');

  const [events, setEvents] = useState([]);

  const laptopWidth = theme.breakpointsNumeric[2];
  const mobileWidth = theme.breakpointsNumeric[1];
  const smMobileWidth = theme.breakpointsNumeric[0];

  useEffect(() => {
    if (!events.length) {
      axios.get(carFindLiveEndpoint, {crossDomain: true})
        .then((res) => {
          console.log(res.data.data, 'events')
          setEvents(res.data.data);
        })
    }
  }, [events, setEvents]);

  return (
    <>
      <Section
        isSmMobile={width <= smMobileWidth}
        id='section-1'
        fullWidth
        heading
        sx={{
          position: 'relative',
          maxHeight: '820px',
        }}
      >
        <ImageOverlay/>
        <ImageContainer
          sx={{
            minHeight: width <= mobileWidth ? 325 : 800
          }}
        >
          <CarHeaderImage
            priority
            alt='tesla'
            layout='fill'
            height={width <= mobileWidth ? 500 : 800}
            src='/static-web/images/contacts/car-header.jpg'
          />
        </ImageContainer>
      </Section>

      <Section
        isSmMobile={width <= smMobileWidth}
        id='section-2'
        fullWidth
      >
        <Text
          sx={{
            ...sectionHeadingStyles
          }}
        >
          <Heading
            as="h1"
            variant='heading.xl'
            color='text.secondary'
            fontFamily='secondary'
          >
            CarFind Live
          </Heading>
        </Text>

        <PageContent>
          <EventsWrapper>
            {events.length > 0 && (
              events.map((carFindEvent, eventIndex) => (
                <>
                  {/*{eventIndex !== 0 && (*/}
                  {/*  <Divider />*/}
                  {/*)}*/}
                  <Event>
                    <Images
                      css={`
                      .image-gallery-thumbnails-container {
                        display: flex;
                      }
    
                      .image-gallery {
                        width: 100%;
                      }
    
                      .image-gallery-content.fullscreen {
                        //top: 250px;
                      }
    
                      .image-gallery-thumbnail {
                        &.active {
                          border: 4px solid #dedede;
                        }
    
                        &:focus, &:hover {
                          border: 4px solid #dedede;
                        }
                      }
    
                      .image-gallery-icon {
                        color: #fff;
                      }
    
                      .image-gallery-play-button {
                        display: none;
                      }
                    `}
                    >
                      <ImageGallery items={
                        JSON.parse(carFindEvent.images).map(i => ({
                          originalHeight: '100%',
                          originalWidth: '400px',
                          thumbnailHeight: '100%',
                          original: `${apiPublicBaseUrl}/${i}`,
                          thumbnail: `${apiPublicBaseUrl}/${i}`,
                        }))
                      }/>
                    </Images>
                    <List>
                      <ListItem
                        sx={{
                          fontSize: 24,
                          // fontWeight: 'bold'
                        }}
                      >
                        {carFindEvent.title}
                      </ListItem>
                      <ListItem><b>Дата та місце:</b> {carFindEvent.date}</ListItem>
                      <ListItem><b>Ціль:</b> {carFindEvent.target}</ListItem>
                      <ListItem>
                        <b>Посилання:</b>
                        <a style={{marginLeft: '5px', color: 'blue'}} href={carFindEvent.video_link} rel="noreferrer" target="_blank">{carFindEvent.video_link}</a>
                      </ListItem>
                      <ListItem dangerouslySetInnerHTML={{__html: carFindEvent.description_ua}} />
                    </List>
                  </Event>
                </>
              ))
            )}
          </EventsWrapper>
        </PageContent>
      </Section>
    </>
  );
};

const Divider = styled(Flex)`
  width: 100%;
  height: 2px;
  background: #dedede;
  margin: 15px 0;
`

const Images = styled(Flex)`
  align-items: ${({isElectric}) => isElectric ? 'flex-start' : 'center'};
  width: 30%;
  @media (max-width: 901px) {
    width: 100%;
  }
`

const EventImage = styled.img`
  height: 250px;
  margin-top: 15px;
  @media (max-width: 901px) {
    height: 100%;
  }
`

const ListItem = styled(Box)`
  p {
    margin: 0
  };
  padding: 10px 0;
  border-bottom: 1px solid gray;
`

const List = styled(Flex)`
  width: 70%;
  margin-left: 25px;
  @media (max-width: 901px) {
    margin-left: 0;
    width: 100%;
  }
  flex-direction: column;
`

const Event = styled(Flex)`
  @media (max-width: 901px) {
    flex-direction: column;
    //align-items: flex-start;
  }
`

const EventsWrapper = styled(Flex)`
  flex-direction: column;
  gap: 25px;
  margin-top: 25px;
`

const CarHeaderImage = styled(Image)`
  width: 100%;
  max-height: 820px;
  object-fit: cover;
  min-height: 325px;
  position: relative;
  @media (max-width: 1000px) {
    min-height: 325px;
  }
`

const ImageOverlay = styled(Box)`
  position: absolute;
  width: 100%;
  height: calc(100% - 2px);
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  min-height: 325px;
`

const ImageContainer = styled(Box)`
  width: 100%;
  height: fit-content;
`

CarFindLive.propTypes = {

};

export default CarFindLive;
