import React, {useCallback, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styled, {css, useTheme} from "styled-components";
import {Box, Flex, Text, Heading} from "rebass/styled-components";
import {useTranslation} from "next-i18next";
import Image from 'next/image';

const IconItems = [
  {
    name: 'key',
    iconBlack: 'key-icon-black.svg',
    iconWhite: 'key-icon-white.svg',
    labels: ['servicesPageKeyLabel1', 'servicesPageKeyLabel2', 'servicesPageKeyLabel3'],
    title: 'servicesPageKeyTitle',
    text: 'servicesPageKeyContent'
  },
  {
    name: 'delivery',
    iconBlack: 'delivery-icon-black.svg',
    iconWhite: 'delivery-icon-white.svg',
    labels: ['servicesPageDeliveryLabel1', 'servicesPageDeliveryLabel2'],
    title: 'servicesPageDeliveryTitle',
    text: 'servicesPageDeliveryContent'
  },
  {
    name: 'customs',
    iconBlack: 'customs-icon-black.svg',
    iconWhite: 'customs-icon-white.svg',
    labels: ['servicesPageCustomsLabel1'],
    title: 'servicesPageCustomsTitle',
    text: `servicesPageCustomsContent`
  },
  {
    name: 'certification',
    iconBlack: 'cert-icon-black.svg',
    iconWhite: 'cert-icon-white.svg',
    labels: ['servicesPageCertificationLabel1'],
    title: 'servicesPageCertificationTitle',
    text: 'servicesPageCertificationContent'
  },
  {
    name: 'repair',
    iconBlack: 'repair-icon-black.svg',
    iconWhite: 'repair-icon-white.svg',
    labels: ['servicesPageRepairLabel1', 'servicesPageRepairLabel2', 'servicesPageRepairLabel3'],
    title: 'servicesPageRepairTitle',
    text: 'servicesPageRepairContent'
  },
]

const Services = ({screenWidth: width}) => {

  const theme = useTheme();
  const circleRef = useRef();
  const circleContainerAnchorRef = useRef();
  const wrapperRef = useRef();
  const { t } = useTranslation('common');

  const [activeItemIndex, setActiveItemIndex] = useState(0);
  // const [currentStep, setCurrentStep] = useState(1);
  const [activeItem, setActiveItem] = useState(IconItems[activeItemIndex]);
  // const [anchorVisible, setIsAnchorVisible] = useState(false);
  // const [tourFinished, setTourFinished] = useState(false);

  // const laptopWidth = theme.breakpointsNumeric[2];
  const mobileWidth = theme.breakpointsNumeric[1];
  const smMobileWidth = theme.breakpointsNumeric[0];

  const onIconClick = useCallback((index) => {
    setActiveItemIndex(index);
    setActiveItem(IconItems[index]);
  }, []);

  // const lockScroll = useCallback(() => {
  //   document.documentElement.style.overflowY = 'hidden';
  //   document.body.scroll = "no";
  //   wrapperRef.current.style.marginRight = "15px";
  // }, []);
  //
  // const unlockScroll = useCallback(() => {
  //   document.documentElement.style.overflowY = 'scroll';
  //   document.body.scroll = "yes";
  //   wrapperRef.current.style.marginRight = "0";
  // }, []);

  // const invokeDebounced = useThrottle(
  //   () => {
  //     if (!tourFinished && width > smMobileWidth) {
  //       const step = 360 / 5;
  //       const nextIndex = (activeItemIndex + 1) > 4 ? 0 : activeItemIndex + 1;
  //       const nextStep = currentStep + 1;
  //       circleRef.current.style.transform = `rotate(${step * nextStep}deg)`;
  //       const icons = document.getElementsByClassName('icon-wrapper');
  //       for (let icon of icons) {
  //         icon.style.transform = `rotate(-${step * nextStep}deg)`
  //       }
  //       if (activeItemIndex === 4) {
  //         unlockScroll();
  //         setTourFinished(true)
  //       }
  //       setCurrentStep(nextStep)
  //       setActiveItemIndex(nextIndex);
  //       setActiveItem(IconItems[nextIndex]);
  //     }
  //   },
  //   100
  // );

  // useEffect(() => {
  //   if (anchorVisible) {
  //     lockScroll();
  //   } else {
  //     unlockScroll();
  //   }
  // }, [anchorVisible, lockScroll, unlockScroll])
  //
  // useEffect(() => {
  //   if (circleContainerAnchorRef?.current && width > smMobileWidth) {
  //     const observer = new IntersectionObserver(
  //       ([entry]) => {
  //         if (entry.isIntersecting) {
  //           setIsAnchorVisible(true);
  //         }
  //       }
  //     )
  //     if (circleContainerAnchorRef?.current) {
  //       observer.observe(circleContainerAnchorRef.current)
  //     }
  //     return () => {
  //       observer.disconnect()
  //     }
  //   }
  // }, [width, smMobileWidth, circleContainerAnchorRef, setIsAnchorVisible])

  return (
    <Wrapper ref={wrapperRef}>
      <Section
        isSmMobile={width <= smMobileWidth}
        id='section-1'
        fullWidth
        heading
        sx={{
          position: 'relative',
          maxHeight: '820px',
          minHeight: '775px'
        }}
      >
        <PageContent>
          <MainTitle
            as='h1'
            fontFamily='secondary'
          >
            {t('servicesPageTitle')}
          </MainTitle>

          <SubHeaderContent>
            <SubheaderItem>
              <Number fontFamily='primary'>1</Number>
              <SubHeaderText fontFamily='primary'>{t('servicesPageTitle1')}</SubHeaderText>
            </SubheaderItem>

            <SubheaderItem>
              <Number fontFamily='primary'>2</Number>
              <SubHeaderText fontFamily='primary'>{t('servicesPageTitle2')}</SubHeaderText>
            </SubheaderItem>

            <SubheaderItem>
              <Number fontFamily='primary'>3</Number>
              <SubHeaderText fontFamily='primary'>{t('servicesPageTitle3')}</SubHeaderText>
            </SubheaderItem>

            <SubheaderItem>
              <Number fontFamily='primary'>4</Number>
              <SubHeaderText fontFamily='primary'>{t('servicesPageTitle5')}</SubHeaderText>
            </SubheaderItem>

            <SubheaderItem>
              <Number fontFamily='primary'>5</Number>
              <SubHeaderText fontFamily='primary'>{t('servicesPageTitle4')}</SubHeaderText>
            </SubheaderItem>
          </SubHeaderContent>
        </PageContent>

        <ImageOverlay/>
        <ImageContainer
          sx={{
            minHeight: width <= mobileWidth ? 325 : 800
          }}
        >
          <ImageArrowTopWrapper>
            <Image
              priority
              alt='arrow-down'
              src='/static-web/images/services/Arrow-1.png'
              layout='fill'
            />
          </ImageArrowTopWrapper>

          <CarHeaderImage
            alt='car-services'
            layout='fill'
            height={width <= mobileWidth ? 500 : 800}
            src='/static-web/images/services/services-main.png'
          />
        </ImageContainer>
      </Section>

      {/*<ReactScrollWheelHandler*/}
      {/*  downHandler={invokeDebounced}*/}
      {/*  onTouchMove={invokeDebounced}*/}
      {/*>*/}
        <Section
          isSmMobile={width <= smMobileWidth}
          id='section-2'
          fullWidth
        >

          <PageContent
            sx={{
              alignItems: 'center'
            }}
          >
            <CircleWrapper>
              <Circle
                id='circle'
                ref={circleRef}
              >
                {IconItems.map((item, index) => {
                  return (
                    <IconWrapper
                      key={`icon-${index}`}
                      index={index}
                      className='icon-wrapper'
                      active={activeItem.name === item.name}
                      onClick={() => {
                        onIconClick(index)
                      }}
                    >
                      <Icon
                        active={activeItem.name === item.name}
                        iconBlack={item.iconBlack}
                        iconWhite={item.iconWhite}
                      />
                    </IconWrapper>
                  )
                })}
              </Circle>
            </CircleWrapper>
            <InfoWrapper>
              <TextBlock>
                <Info>
                  <LabelsWrapper fontFamily='primary'>
                    {activeItem.labels.map((text, index) => (
                      <>
                        <InfoLabel>{t(text)}</InfoLabel>
                        {activeItem.labels[index + 1] && (
                          <BorderLine/>
                        )}
                      </>
                    ))}
                  </LabelsWrapper>
                  <TextWrapper>
                    <SubHeader variant='body.md'>{t(activeItem.title)}</SubHeader>
                    <TextMain
                      variant='body.sm'
                      dangerouslySetInnerHTML={{__html: t(activeItem.text)}}
                    >
                    </TextMain>
                  </TextWrapper>
                </Info>
              </TextBlock>
            </InfoWrapper>
          </PageContent>

          {width >= 450 && (
            <ImageArrowDownWrapper
            >
              <Image
                alt='arrow-up'
                layout='fill'
                src='/static-web/images/services/Arrow-2.png'
              />
            </ImageArrowDownWrapper>
          )}

          <Box
            ref={circleContainerAnchorRef}
            sx={{
              position: 'relative',
              bottom: '-125px',
              width: '1%',
              height: '1px',
              mt: 'auto'
            }}
          />
        </Section>
      {/*</ReactScrollWheelHandler>*/}
    </Wrapper>
  );
};

const ImageArrowDownWrapper = styled(Box)`
  width: 163px;
  height: 213px;
  position: absolute;
  right: 15px;
  bottom: 0px;
  z-index: 150;

  @media (max-width: 1600px) {
    right: -25px;
  }


  @media (max-width: 900px) {
    right: 75px;
    width: 100px;
  }

  @media (max-width: 600px) {
    right: 20px;
    width: 100px;
  }
`

const ImageArrowTopWrapper = styled(Box)`
  position: absolute;
  height: 150px;
  width: 75px;
  left: 100px;
  bottom: -80px;
  z-index: 12;

  @media (max-width: 1600px) {
    left: 200px;
  }

  @media (max-width: 1200px) {
    left: 55px;
  }

  @media (max-width: 600px) {
    width: 50px;
    bottom: -50px;
  }
`

const Wrapper = styled(Box)``

const SubHeader = styled(Text)`
  font-weight: bold;
  text-transform: uppercase;
`

const TextMain = styled(Text)`
  margin-top: 10px;
`

const TextWrapper = styled(Flex)`
  flex-direction: column;
`

const BorderLine = styled(Flex)`
  height: 2px;
  width: 75%;
  align-self: center;
  background-color: #fff;
`

const InfoLabel = styled(Flex)`
  height: 100px;
  justify-content: center;
  align-items: center;
  padding: 15px 5px;
  color: #fff;
  text-align: center;
  font-size: 12px;
`

const LabelsWrapper = styled(Flex)`
  width: 125px;
  flex-direction: column;
  position: absolute;
  left: -60px;
  background: #868c90;
`

const Info = styled(Flex)`
  padding: 15px 75px 15px 100px;
  position: relative;
  @media (max-width: 1200px) {
    padding: 15px 25px 15px 75px;
  }
  @media (max-width: 600px) {
    min-height: 725px;
  }
`

const TextBlock = styled(Flex)`
  width: 100%;
  height: 500px;
  background-color: #F6F7F9;
  box-shadow: 0 22px 32px -6px rgb(21 34 66 / 15%), 0 0 1px rgb(21 34 66 / 26%);

  @media (max-width: 1200px) {
    height: 600px;
  }

  @media (max-width: 600px) {
    height: fit-content;
  }
`

const Icon = styled(Box)`
  background-size: contain;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;

  @media (max-width: 1200px) {
    ${({active}) => css`
      width: ${active ? '115px' : '100px'};
      height: ${active ? '115px' : '100px'};
    `}
  }
  
  @media (max-width: 450px) {
    ${({active}) => css`
      width: ${active ? '100px' : '65px'};
      height: ${active ? '100px' : '65px'};
    `}
  }

  ${({iconBlack, iconWhite, active}) => css`
    width: ${active ? '150px' : '125px'};
    height: ${active ? '150px' : '125px'};
    background: url('/static-web/images/icons/services/${iconWhite}') center center no-repeat;

    ${({active}) => active && css`
      background-image: url('/static-web/images/icons/services/${iconBlack}');
    `}
    &:hover {
      cursor: pointer;
    }
  `}
`

const IconWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 50%;
  width: fit-content;
  background-color: #fff;
  
  transform: rotate(-72deg);

  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;

  @media (max-width: 1200px) {
    ${({index, active}) => index === 0 && css`
      top: -40px;
      left: 100px;

      ${active && css`
        left: 90px;
        top: -50px;
      `}
    `}
    ${({index, active}) => index === 1 && css`
      top: 65px;
      left: -45px;

      ${active && css`
        left: -55px;
        top: 55px;
      `}
    `}
    ${({index, active}) => index === 2 && css`
      top: 220px;
      left: 10px;

      ${active && css`
        left: 0;
        top: 220px;
      `}
    `}
    ${({index, active}) => index === 3 && css`
      top: 220px;
      left: 180px;

      ${active && css`
        left: 170px;
        top: 220px;
      `}
    `}
    ${({index, active}) => index === 4 && css`
      top: 70px;
      left: 240px;

      ${active && css`
        left: 235px;
        top: 60px;
      `}
    `}
  }
  
  @media (max-width: 450px) {
    ${({index, active}) => index === 0 && css`
      top: -30px;
      left: 100px;

      ${active && css`
        left: 90px;
        top: -50px;
      `}
    `}
    ${({index, active}) => index === 1 && css`
      top: 45px;
      left: -25px;

      ${active && css`
        left: -45px;
        top: 35px;
      `}
    `}
    ${({index, active}) => index === 2 && css`
      top: 190px;
      left: 20px;

      ${active && css`
        left: 0px;
        top: 180px;
      `}
    `}
    ${({index, active}) => index === 3 && css`
      top: 200px;
      left: 160px;

      ${active && css`
        left: 140px;
        top: 190px;
      `}
    `}
    ${({index, active}) => index === 4 && css`
      top: 65px;
      left: 205px;

      ${active && css`
        left: 180px;
        top: 35px;
      `}
    `}
  }

  ${({index, active}) => index === 0 && css`
    top: -50px;
    left: 150px;

    ${active && css`
      left: 140px;
      top: -60px;
    `}
  `}
  ${({index, active}) => index === 1 && css`
    top: 85px;
    left: -55px;

    ${active && css`
      left: -65px;
      top: 75px;
    `}
  `}
  ${({index, active}) => index === 2 && css`
    top: 290px;
    left: 20px;

    ${active && css`
      left: 10px;
      top: 280px;
    `}
  `}
  ${({index, active}) => index === 3 && css`
    top: 300px;
    left: 250px;

    ${active && css`
      left: 240px;
      top: 290px;
    `}
  `}
  ${({index, active}) => index === 4 && css`
    top: 100px;
    left: 325px;

    ${active && css`
      left: 315px;
      top: 90px;
    `}
  `}
`

const Circle = styled(Flex)`
  position: relative;
  border: 2px solid #000;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  
  transition: all 0.5s;
  
  transform: rotate(72deg);

  @media (max-width: 1200px) {
    width: 300px;
    height: 300px;
  }

  @media (max-width: 900px) {
    margin-bottom: 75px;
  }
  
  @media (max-width: 600px) {
    min-width: 300px;
    min-height: 300px;
  }

  @media (max-width: 450px) {
    width: 250px;
    height: 250px;
    min-width: 250px;
    min-height: 250px;
  }
`

const CircleWrapper = styled(Flex)`
  width: 50%;
  justify-content: center;
  align-items: center;

  @media (max-width: 1200px) {
    justify-content: start;
    width: 45%;
  }

  @media (max-width: 900px) {
    width: 100%;
    justify-content: center;
    margin-top: 75px;
  }
`

const InfoWrapper = styled(Flex)`
  width: 45%;
  margin-left: auto;

  @media (max-width: 1200px) {
    width: 50%;
  }

  @media (max-width: 900px) {
    width: 75%;
    margin: 0 auto;
  }
  @media (max-width: 600px) {
    width: 90%;
    margin: 0 auto;
  }
`

const SubheaderItem = styled(Flex)`
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
  text-transform: uppercase;
  min-width: 230px;
`
const SubHeaderText = styled(Heading)`
  font-size: 14px;
  @media (max-width: 900px) {
    font-size: 14px;
  }

  @media (max-width: 600px) {
    font-size: 12px;
  }
`

const Number = styled(Flex)`
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 45px;
  height: 45px;
  min-width: 45px;
  min-height: 45px;
  border-radius: 50%;
  border: 2px solid #fff;
  font-size: 22px;
  font-weight: bold;
  z-index: 101;
  margin-right: 10px;
  @media (max-width: 900px) {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    font-size: 18px;
  }

  @media (max-width: 600px) {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    font-size: 16px;
  }
`

const SubHeaderContent = styled(Flex)`
  flex-direction: column;
  position: absolute;
  color: #fff;
  right: 150px;
  top: 350px;

  @media (max-width: 1600px) {
    right: 12%;
    top: 300px;
  }

  @media (max-width: 1200px) {
    right: 50px;
    top: 350px;
  }
  
  @media (max-width: 900px) {
    top: 250px;
  }
  
  @media (max-width: 600px) {
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
  }
`

const MainTitle = styled(Heading)`
  font-weight: bold;
  position: absolute;
  color: #fff;
  right: 145px;
  max-width: 360px;
  text-transform: uppercase;
  padding: 0;
  font-size: 58px;
  line-height: 60px;
  text-align: right;
  top: 150px;
  
  @media (max-width: 1600px) {
    right: 7.5%;
    top: 115px;
  }

  @media (max-width: 1200px) {
    padding: 12px;
    right: 50px;
    top: 125px;
  }

  @media (max-width: 900px) {
    text-align: right;
    padding: 0;
    font-size: 46px;
    line-height: 46px;
    top: 100px;
  }

  @media (max-width: 600px) {
    font-size: 28px;
    max-width: 100%;
    text-align: center;

    left: 50%;
    width: 100%;
    transform: translateX(-50%);
  }
`;

const PageContent = styled(Flex)`
  padding: 0;
  width: 100%;
  max-width: 1600px;
  position: relative;
  z-index: 99;
  flex-wrap: wrap;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`

const Section = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({heading}) => heading ? 0 : '125px 50px'};
  max-width: ${({fullWidth, theme}) => fullWidth ? '100%' : theme.breakpoints[2]};
  height: ${(({staticHeight}) => staticHeight ? `${staticHeight}px` : 'fit-content')};

  :not(:first-child) {
    margin: 50px auto 0;
  }

  ${({isSmMobile}) => isSmMobile && css`
    padding: ${({heading}) => heading ? 0 : '20px 20px 30px'};
  `}

  ${({id}) => id === 'section-1' && css`
    @media (max-width: 900px) {
      min-height: 550px;
    }
  `}

  ${({id}) => id === 'section-2' && css`
    position: relative;
    background: #fff;
    @media (max-width: 1200px) {
      padding: 50px 50px;
    }
  `}
`

const CarHeaderImage = styled(Image)`
  background: #F6F7F9;
  width: 100%;
  object-fit: cover;
  min-height: 325px;
  max-height: 820px;
  position: relative;
  left: 0;
  top: 0;

  @media (max-width: 1600px) {
    height: 775px;
  }

  @media (max-width: 1000px) {
    min-height: 200px;
  }

  @media (max-width: 700px) {
    min-height: 185px;
  }

  @media (max-width: 600px) {
    height: 550px;
  }
`

const ImageOverlay = styled(Box)`
  position: absolute;
  width: 100%;
  height: calc(100% - 3px);
  background: rgba(0, 0, 0, 0.4);
  min-height: 325px;
  z-index: 1;
`

const ImageContainer = styled(Box)`
  width: 100%;
  height: fit-content;
  background: #F6F7F9;
`

Services.propTypes = {
  screenWidth: PropTypes.number
};

export default Services;
